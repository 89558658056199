@charset "UTF-8";
html, body {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%; }

body, textarea {
  font-family: "Noto Sans", "WenQuanYi Micro Hei", "Noto Sans CJK TC", "Microsoft JhengHei"; }

body {
  color: #333;
  font-size: 16px;
  line-height: 1;
  background-color: #FFF; }

*, *::before, *::after {
  box-sizing: border-box; }

button, input, select, textarea {
  outline: none 0 !important; }

button {
  display: inline-block;
  margin: 0;
  border-width: 0;
  padding: 0;
  color: inherit;
  font-size: 1em;
  font-family: inherit;
  font-weight: inherit;
  line-height: inherit;
  background-color: transparent; }

a {
  text-decoration: none;
  color: inherit;
  font-size: 1em; }

h1, h2, h3, h4, h5, h6 {
  margin: 0;
  padding: 0;
  font-size: 1em; }

ul, ol {
  margin: 0;
  padding: 0;
  list-style: none; }

pre {
  font-family: inherit; }

@font-face {
  font-family: "FontAwesome";
  src: url("../fonts/fontawesome-webfont.eot?v=4.6.3");
  src: url("../fonts/fontawesome-webfont.eot?#iefix&v=4.6.3") format("embedded-opentype"), url("../fonts/fontawesome-webfont.woff2?v=4.6.3") format("woff2"), url("../fonts/fontawesome-webfont.woff?v=4.6.3") format("woff"), url("../fonts/fontawesome-webfont.ttf?v=4.6.3") format("truetype"), url("../fonts/fontawesome-webfont.svg?v=4.6.3#fontawesomeregular") format("svg");
  font-weight: normal;
  font-style: normal; }
.fa {
  font-family: "FontAwesome";
  text-decoration: inherit;
  font-style: inherit; }
.fa::before {
  content: "";
  font-family: "FontAwesome";
  color: inherit;
  font-size: inherit;
  line-height: inherit; }

.fa-inner::before {
  font-family: "FontAwesome"; }

.fa-search::before {
  content: ""; }

.fa-time::before {
  content: ""; }

.fa-cart::before {
  content: ""; }

.fa-tel::before {
  content: ""; }

.fa-mobile::before {
  content: ""; }

.fa-fax::before {
  content: ""; }

.fa-mail::before {
  content: ""; }

.fa-house::before {
  content: ""; }

.fa-download::before {
  content: ""; }

.fa-cart-plus::before {
  content: ""; }

.fa-info::before {
  content: ""; }

.fa-left::before {
  content: ""; }

.fa-right::before {
  content: ""; }

.fa-dbleft::before {
  content: ""; }

.fa-dbright::before {
  content: ""; }

.fa-plus::before {
  content: ""; }

.fa-minus::before {
  content: ""; }

.fa-close::before {
  content: ""; }

.tac {
  text-align: center; }

.tal {
  text-align: left; }

.tar {
  text-align: right; }

.wrap, .container-wrap {
  display: block;
  width: 100%;
  height: 100%; }

.wrap, .box {
  position: relative; }

.main-wrap {
  padding-bottom: calc( 10px + 1vh );
  height: auto;
  min-height: 100%;
  background-color: #E9F8F5; }

.box, .container {
  position: relative;
  display: inline-block; }

.section, .inner-gap {
  width: 100%; }

.section > .inner-gap {
  margin-bottom: 1.5em; }
.section > .inner-gap:last-child {
  margin-bottom: 0; }
.section > .inner-gap > .title {
  margin-bottom: 1em;
  padding: 0.2em 0.75em 0.175em;
  font-size: 1.375em;
  text-shadow: 0.05em 0.05em 0 #E9F8F5, -0.05em 0.05em 0 #E9F8F5, 0.05em -0.05em 0 #E9F8F5, 0.05em 0 0 #E9F8F5, -0.05em 0 0 #E9F8F5, 0 0.05em 0 #E9F8F5, 0 -0.05em 0 #E9F8F5;
  background-image: linear-gradient(0deg, rgba(18, 164, 181, 0.6) 0.2em, transparent 0.2em); }

.inner-gap {
  padding: 10px 2%; }
.inner-gap > .article {
  padding: 0 1em; }
.inner-gap > .article img {
  max-width: 100%; }
.inner-gap > .article p, .inner-gap > .article pre {
  margin: 0.5em 0;
  width: 100%;
  line-height: 1.6;
  word-break: break-all;
  word-wrap: break-word;
  white-space: pre-wrap; }
.inner-gap > .article a {
  margin: 0 0.125em;
  padding: 0 0.25em;
  color: #333;
  text-shadow: 0.05em 0.05em 0 #E9F8F5, -0.05em 0.05em 0 #E9F8F5, 0.05em -0.05em 0 #E9F8F5, 0.05em 0 0 #E9F8F5, -0.05em 0 0 #E9F8F5, 0 0.05em 0 #E9F8F5, 0 -0.05em 0 #E9F8F5;
  background-image: linear-gradient(0deg, rgba(18, 164, 181, 0.6) 0.4em, transparent 0.4em);
  background-position: center -0.05em;
  transition: background-position 0.15s ease-in-out, color 0.15s ease-in-out;
  overflow: hidden; }
.inner-gap > .article a:hover {
  color: #063b41;
  background-position-y: -0.4em; }
.inner-gap > .article ul, .inner-gap > .article ol {
  margin-top: 1em;
  margin-bottom: 1em;
  margin-left: 4em;
  list-style: disc;
  line-height: inherit; }
.inner-gap > .article ul > li, .inner-gap > .article ol > li {
  line-height: 1.6; }
.inner-gap > .article ol {
  counter-reset: section;
  list-style: none; }
.inner-gap > .article ol > li::before {
  content: counters(section,".") ".";
  counter-increment: section;
  margin-right: 0.5em; }

.card {
  margin: 0 auto;
  padding: 1.5em;
  width: 80%;
  background-color: #93D2DB; }
.card > .title {
  margin: 2em 0;
  font-size: 1.25em; }

.flex {
  display: flex;
  align-items: stretch;
  align-content: stretch; }

.f-col {
  flex-direction: column; }

.f-mc {
  align-items: center;
  justify-content: center; }

.f-i-g {
  flex-grow: 1; }

.f-sa {
  justify-content: space-around; }

.indent {
  margin: 0 auto;
  max-width: 1280px; }

.dym-pad {
  padding-right: calc( 5px + 1vw );
  padding-left: calc( 5px + 1vw ); }

.col2 {
  display: flex;
  width: 100%; }
.col2 > .right, .col2 > .left {
  width: 47%; }
.col2 > .right {
  order: 2;
  margin-left: 3%; }
.col2 > .left {
  order: 1;
  margin-right: 3%; }

.cb::after {
  content: "";
  display: block;
  height: 0;
  font-size: 0;
  line-height: 0;
  clear: both; }

.select, .inp, .textarea {
  margin: 0;
  border: 0 solid transparent;
  padding: 0;
  color: inherit;
  font-style: inherit;
  font-size: inherit;
  line-height: inherit;
  background-color: transparent; }

a, .btn {
  transition: all 0.15s ease-in-out; }

.a-block:hover {
  background-color: rgba(49, 222, 135, 0.48); }

button.btn {
  border: 0 solid transparent;
  cursor: pointer; }

.btn-submit, .btn-cancel {
  border-radius: 0.375em;
  font-weight: bold; }

.btn-submit {
  padding: 0.425em 2em 0.35em;
  color: #FFF;
  background-color: #2bcbcd; }
.btn-submit:hover {
  background-color: #51d9da; }

.btn-cancel:hover {
  background-color: rgba(0, 0, 0, 0.08); }

.btn-drop::after {
  content: "";
  display: inline-block;
  margin-left: 5px;
  margin-bottom: 1px;
  border-top: 6px solid #FFF;
  border-right: 4px solid transparent;
  border-bottom-width: 0;
  border-left: 4px solid transparent;
  width: 0;
  height: 0;
  font-size: 0;
  line-height: 0; }

.btn-product-act {
  font-weight: bold; }

.open > .btn-drop::after {
  border-top-width: 0;
  border-right: 4px solid transparent;
  border-bottom: 6px solid #FFF;
  border-left: 4px solid transparent; }

.list-article {
  padding: 10px 2%; }
.list-article > .item {
  padding-bottom: 0.5em;
  margin-bottom: 0.5em;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12); }
.list-article > .item:last-child {
  margin-bottom: 0;
  border-bottom-width: 0; }
.list-article > .item > a:hover {
  background-color: rgba(0, 0, 0, 0.08); }
.list-article > .item > a > .time {
  padding: 0.5em 5px;
  text-align: center;
  width: calc(10em + 10px);
  min-width: calc(10em + 10px); }
.list-article > .item > a > .title {
  padding: 0.5em 8px;
  min-width: 0;
  word-break: break-all; }

.list-fixed {
  display: flex;
  flex-direction: column;
  margin: 1em auto;
  width: 100%; }
.list-fixed > .item {
  display: flex;
  align-items: stretch;
  align-content: stretch;
  margin-bottom: 0.5em; }
.list-fixed > .item > .left, .list-fixed > .item > .right {
  display: inline-block;
  word-break: break-all; }
.list-fixed > .item > .left {
  margin-right: 1em;
  width: 30%;
  font-weight: bold;
  text-align: right; }
.list-fixed > .item > .right {
  flex-grow: 1; }

.article-pages {
  margin-top: calc( 16px + 2vh );
  margin-bottom: 16px;
  padding-right: 2em;
  padding-left: 2em;
  left: 1rem;
  line-height: 1.6; }
.article-pages > .head, .article-pages > .tail, .article-pages > .head > .first, .article-pages > .tail > .last, .article-pages > .head > .prev, .article-pages > .tail > .next {
  display: inline-block; }
.article-pages > .head {
  float: left;
  margin-right: 0.5em; }
.article-pages > .head > .first, .article-pages > .head > .prev {
  float: left; }
.article-pages > .head > .first {
  margin-right: 0.5em; }
.article-pages > .tail {
  float: right;
  margin-left: 0.5em; }
.article-pages > .tail > .last, .article-pages > .tail > .next {
  float: right; }
.article-pages > .tail > .last {
  margin-left: 0.5em; }
.article-pages > .head > .first, .article-pages > .tail > .last, .article-pages > .head > .prev, .article-pages > .tail > .next, .article-pages > .pages > .pg {
  padding: 0.25em 0.5em;
  text-align: center; }
.article-pages > .head > .first, .article-pages > .tail > .last, .article-pages > .head > .prev, .article-pages > .tail > .next {
  box-sizing: content-box;
  width: 1em; }
.article-pages > .head > .first:not(.disabled):hover, .article-pages > .tail > .last:not(.disabled):hover, .article-pages > .head > .prev:not(.disabled):hover, .article-pages > .tail > .next:not(.disabled):hover {
  background-color: #b9e2e8; }
.article-pages > .head > .disabled, .article-pages > .tail > .disabled {
  color: #bfbfbf;
  cursor: not-allowed; }
.article-pages > .pages {
  text-align: center;
  cursor: default;
  white-space: nowrap;
  overflow: hidden;
  -moz-user-select: none;
  -webkit-user-select: none;
  user-select: none; }
.article-pages > .pages > .pg {
  display: inline-block;
  margin: 0 0.5em; }
.article-pages > .pages > .pg:not(.now):hover {
  background-color: #b9e2e8; }
.article-pages > .pages > .now {
  color: #FFF;
  background-color: #12A4B5;
  cursor: default; }

.img-wrap > img {
  display: block;
  margin: 0 auto;
  max-width: 100%;
  max-height: 100%; }

.table {
  display: table;
  table-layout: fixed;
  border-collapse: collapse;
  padding: 10px 2%;
  width: 100%; }
.table > .tr {
  display: table-row; }
.table > .tr > .th, .table > .tr > .td {
  display: table-cell;
  vertical-align: middle; }
.table > .tr > .th {
  font-weight: bold;
  text-align: center; }
.table > .tr > .th[data-style~="single"], .table > .tr > .td[data-style~="single"] {
  white-space: nowrap; }
.table > .tr > .th[data-style~="multi"], .table > .tr > .td[data-style~="multi"] {
  word-break: break-word; }
.table > .tr > .th[data-style~="center"], .table > .tr > .td[data-style~="center"] {
  text-align: center; }
.table > .thead > .th, .table > .thead > .td {
  padding: 0.5em 1em; }

.table-flex {
  display: flex;
  flex-direction: column; }
.table-flex > .tr {
  display: flex; }
.table-flex > .tr > .td[data-style~="grow"], .table-flex > .tr > .th[data-style~="grow"] {
  flex-grow: 1; }
.table-flex > .tfoot {
  align-items: flex-end;
  justify-content: flex-end; }

.breadcrumb {
  display: block;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  font-size: 0.75em; }
.left .breadcrumb {
  border-bottom: 1px solid #12A4B5; }
.breadcrumb > .crumb {
  position: relative;
  display: inline-block;
  margin-right: 1.4em; }
.breadcrumb > .crumb::after {
  content: "/";
  top: 0.3rem;
  right: -1.4em;
  position: absolute;
  height: 1em;
  width: 1em; }
.breadcrumb > .crumb:last-child {
  margin-right: 0; }
.breadcrumb > .crumb:last-child::after {
  content: none; }
.breadcrumb > .crumb > a {
  display: block;
  padding: 0.3rem 1em 0.275rem;
  max-width: 100px;
  overflow: hidden;
  text-overflow: ellipsis; }
.breadcrumb:not(.checkout-step) > .crumb:last-child {
  padding: 0.3rem 1em 0.275rem;
  max-width: 100px;
  color: #12A4B5;
  overflow: hidden;
  text-overflow: ellipsis; }

.checkout-step {
  border-bottom-width: 0;
  text-align: center;
  font-size: 1em; }
.checkout-step > .crumb {
  border-bottom: 2px solid transparent;
  padding: 0.3rem 1.5em 0.275em;
  max-width: none;
  color: #FFF;
  background-color: #93D2DB; }
.checkout-step > .crumb::after {
  content: "";
  right: -1.2em;
  font-family: "FontAwesome";
  color: #333;
  font-size: inherit;
  line-height: inherit; }
.checkout-step > .now {
  border-bottom-color: #93D2DB;
  color: #333;
  background-color: transparent; }
.checkout-step > .now::after, .checkout-step > .now ~ .crumb {
  color: rgba(51, 51, 51, 0.24); }
.checkout-step > .now ~ .crumb {
  background-color: transparent; }
.checkout-step > .now ~ .crumb::after {
  right: -1.4em; }

.lightbox {
  top: 0;
  left: 0;
  display: block;
  position: fixed;
  padding: 10px !important;
  width: 100%;
  height: 100%;
  text-align: center;
  background-color: rgba(0, 0, 0, 0.48);
  overflow-x: hidden;
  overflow-y: auto;
  z-index: 9999; }
.lightbox:not(.in) {
  display: none; }
.lightbox > .lightbox-content {
  display: inline-block;
  position: relative;
  padding: 26px;
  min-width: 0;
  max-width: 100%;
  background-color: #FFF; }
.lightbox > .lightbox-content > .btn-close {
  top: 5px;
  right: 5px;
  position: absolute;
  width: 48px;
  height: 48px;
  font-size: 1.25em;
  opacity: 0.5;
  transition: all 0.15s ease-in-out;
  z-index: 10000; }
.lightbox > .lightbox-content > .btn-close:hover {
  color: #FFF;
  background-color: #12A4B5;
  opacity: 1; }

.modal-open, .lightbox-open {
  overflow: hidden; }

.inp-num-wrap > .lb-txt {
  display: inline-block;
  align-self: center;
  margin-right: 1em;
  font-weight: bold; }
.inp-num-wrap > .inp-wrap {
  display: inline-flex; }
.inp-num-wrap > .inp-wrap > .inp {
  border-bottom: 1px solid #93D2DB;
  padding: 0.525em 15px 0.5em;
  width: 100%;
  text-align: center;
  transition: all 0.15s ease-in-out; }
.inp-num-wrap > .inp-wrap > .inp:focus {
  background-color: #FFF; }
.inp-num-wrap > .inp-wrap > .btn {
  padding: 0.525em 1em 0.5em;
  color: #FFF;
  background-color: #93D2DB; }
.inp-num-wrap > .inp-wrap > .btn:hover {
  background-color: #14b9cc; }

span.currency::before {
  content: "$";
  margin-right: 0.125em; }

.dropdown-menu {
  display: none;
  top: 100%;
  position: absolute;
  margin-top: 0;
  border: 0 solid transparent;
  border-radius: 0 0 0.375em 0.375em;
  min-width: 150px;
  max-width: 100%;
  color: #FFF;
  background-color: #12A4B5;
  z-index: 950; }
.dropdown-menu > li {
  display: inline-block;
  margin-top: calc( 5px + 1vh );
  text-align: left; }
.dropdown-menu > li::after {
  content: "";
  display: block;
  height: 0;
  font-size: 0;
  line-height: 0;
  clear: both; }
.dropdown-menu > li > ul {
  float: left;
  display: inline-block;
  width: 100%; }
.dropdown-menu > li > ul > li > a, .dropdown-menu > li > ul > .dropdown-header {
  padding: 5px 10%;
  word-break: break-all; }
.dropdown-menu > li > ul > li > a {
  display: block;
  word-break: break-all; }
.dropdown-menu > li > ul > li > a:hover {
  background-color: rgba(255, 255, 255, 0.24); }
.dropdown-menu > li > ul > .dropdown-header {
  margin-bottom: 0.5em;
  font-weight: bold;
  font-size: 1.25em; }

::-webkit-input-placeholder {
  color: #12A4B5;
  font-weight: bold; }

::-moz-placeholder {
  color: #12A4B5;
  font-weight: bold; }

:-ms-input-placeholder {
  color: #12A4B5;
  font-weight: bold; }

:-moz-placeholder {
  color: #12A4B5;
  font-weight: bold; }

.main-wrap > .head {
  display: inline-block;
  width: 100%;
  background-color: #FFF;
  z-index: 900; }
.main-wrap > .head .logo {
  margin-top: calc( 5px + .8vh );
  margin-bottom: calc( 5px + .8vh );
  font-size: 0 !important; }
.main-wrap > .head .logo > a {
  display: block; }
.main-wrap > .head .logo > a > img {
  display: block;
  max-width: 100%;
  max-height: 100%; }
.main-wrap > .head .nav-wrap {
  background-color: #7CBDB1; }
.main-wrap > .head .nav-wrap .nav {
  position: relative;
  color: #FFF; }
.main-wrap > .head .nav-wrap .nav > .nav-list > .item {
  float: left; }
.main-wrap > .head .nav-wrap .nav > .nav-list > .item > a, .main-wrap > .head .nav-wrap .nav > .nav-list > .item > .btn {
  display: block;
  height: 100%; }
.main-wrap > .head .nav-wrap .nav > .shop-cart {
  float: right; }
.main-wrap > .head .nav-wrap .nav > .shop-cart > .btn > .badge {
  margin-left: 0.5em; }
.main-wrap > .head .nav-wrap .nav > .nav-list > .item > a, .main-wrap > .head .nav-wrap .nav > .nav-list > .item > .btn, .main-wrap > .head .nav-wrap .nav > .shop-cart > .btn {
  border-bottom: 5px solid transparent;
  padding: calc( .5vh + 1em ) 1em calc( .5vh + 1em - 5px );
  cursor: pointer; }
.main-wrap > .head .nav-wrap .nav > .nav-list > .item > a:hover, .main-wrap > .head .nav-wrap .nav > .nav-list > .item > .btn:hover, .main-wrap > .head .nav-wrap .nav > .shop-cart > .btn:hover {
  background-color: rgba(255, 255, 255, 0.24); }
.main-wrap > .head .nav-wrap .open > .btn {
  border-bottom-color: rgba(255, 255, 255, 0.24) !important;
  background-color: rgba(0, 0, 0, 0.28);
  background-clip: padding-box; }
.main-wrap > .head .nav-wrap .btn-drop:after {
  border-top-color: #FFF; }
.main-wrap > .head .nav-wrap .shop-cart > .dropdown-menu {
  right: 0;
  padding: 0 calc( 8px + .8vw ) calc( 5px + 1vh ); }
.main-wrap > .head .nav-wrap .shop-cart > .dropdown-menu > .empty {
  margin-top: calc( 5px + 1vh );
  font-weight: bold; }
.main-wrap > .head .nav-wrap .shop-cart > .dropdown-menu > .item {
  position: relative;
  margin-top: 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.16);
  width: 100%;
  height: 90px;
  background-clip: padding-box;
  transition: all 0.15s ease-in-out; }
.main-wrap > .head .nav-wrap .shop-cart > .dropdown-menu > .item:first-child {
  margin-top: calc( 5px + 1vh ); }
.main-wrap > .head .nav-wrap .shop-cart > .dropdown-menu > .item:last-of-type {
  border-bottom-width: 0; }
.main-wrap > .head .nav-wrap .shop-cart > .dropdown-menu > .item:hover {
  background-color: rgba(255, 255, 255, 0.12); }
.main-wrap > .head .nav-wrap .shop-cart > .dropdown-menu > .item > a {
  display: block;
  padding: 9px 3em 8px 8px;
  width: 100%;
  height: 100%;
  white-space: nowrap; }
.main-wrap > .head .nav-wrap .shop-cart > .dropdown-menu > .item > a > .img-wrap {
  float: left;
  margin-right: 1em;
  height: 100%; }
.main-wrap > .head .nav-wrap .shop-cart > .dropdown-menu > .item > a > .info {
  display: block;
  height: 100%; }
.main-wrap > .head .nav-wrap .shop-cart > .dropdown-menu > .item > a > .info > .title {
  display: block;
  height: 46px;
  line-height: 46px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; }
.main-wrap > .head .nav-wrap .shop-cart > .dropdown-menu > .item > a > .info > .amount, .main-wrap > .head .nav-wrap .shop-cart > .dropdown-menu > .item > a > .info > .total {
  height: 26px;
  font-size: 0.75em;
  line-height: 26px; }
.main-wrap > .head .nav-wrap .shop-cart > .dropdown-menu > .item > a > .info > .amount {
  float: left;
  margin-right: 1em;
  padding: 0 1.25em;
  background-color: rgba(0, 0, 0, 0.08); }
.main-wrap > .head .nav-wrap .shop-cart > .dropdown-menu > .item > a > .info > .amount::before {
  content: "×";
  margin-right: 0.5em; }
.main-wrap > .head .nav-wrap .shop-cart > .dropdown-menu > .item > a > .info > .total {
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; }
.main-wrap > .head .nav-wrap .shop-cart > .dropdown-menu > .item > .delete {
  top: 0;
  right: 0;
  position: absolute;
  height: 100%;
  width: 2em; }
.main-wrap > .head .nav-wrap .shop-cart > .dropdown-menu > .item > .delete > .btn {
  width: 100%;
  height: 100%;
  opacity: 0.5; }
.main-wrap > .head .nav-wrap .shop-cart > .dropdown-menu > .item > .delete > .btn:hover {
  background-color: rgba(255, 255, 255, 0.12);
  opacity: 1; }
.main-wrap > .head .nav-wrap .shop-cart > .dropdown-menu > .btn-checkout {
  display: block;
  margin-top: 1em;
  border-radius: 0.3em;
  padding: 0.75em 1.25em 0.725em;
  width: 100%;
  text-align: center;
  background-color: #2bcbcd; }
.main-wrap > .head .nav-wrap .shop-cart > .dropdown-menu > .btn-checkout:hover {
  background-color: #51d9da; }
.main-wrap > .head .nav-wrap .nav-list > li > .mega-dropdown-menu {
  left: 0;
  padding: 0 0 calc( 5px + 1vh ) 3%;
  width: 100%; }
.main-wrap > .head .nav-wrap .nav-list > li > .mega-dropdown-menu > li {
  float: left;
  margin-right: 3%;
  width: 22%;
  vertical-align: top; }
.main-wrap > .head .nav-wrap .open > .dropdown-menu {
  display: inline-block !important; }
.main-wrap > .head .nav-wrap .small-nav-list {
  display: none; }
.main-wrap > .head .nav-wrap .small-nav-list > .btn {
  padding: calc( .5vh + 1em ) 1em calc( .5vh + 1em - 5px );
  border-bottom: 5px solid transparent; }
.main-wrap > .head .nav-wrap .small-nav-list > .btn::before {
  content: "";
  display: inline-block;
  width: 1em;
  font-family: "FontAwesome";
  color: inherit;
  font-size: inherit;
  line-height: inherit; }
.main-wrap > .head .nav-wrap .small-nav-list > .btn:hover {
  background-color: rgba(255, 255, 255, 0.24); }
.main-wrap > .head .nav-wrap .small-nav-list > .dropdown-menu {
  left: 0;
  display: none;
  padding: 0 calc( 8px + .8vw ) calc( 5px + 1vh ); }
.main-wrap > .head .nav-wrap .small-nav-list > .dropdown-menu > .item {
  display: block; }
.main-wrap > .head .nav-wrap .small-nav-list > .dropdown-menu > .item > a {
  display: block;
  padding: calc( 5px + 1vh ) 1em; }
.main-wrap > .head .nav-wrap .small-nav-list > .dropdown-menu > .item > a:hover {
  background-color: rgba(255, 255, 255, 0.24); }
.main-wrap > .head .nav-wrap .small-nav-list.open > .btn::before {
  content: ""; }

.main-wrap > .content > .section > h2.title, .main-wrap > .content > .col2.products > .right > .section > h2.title, .main-wrap > .content > .section > .left > h2.title, .main-wrap > .content > .section > .right > h2.title {
  display: block;
  margin-bottom: calc( 15px + .5vh );
  padding-bottom: 4px;
  padding-left: 0.5em;
  font-size: 1.75em;
  word-break: break-all;
  text-shadow: 0.05em 0.05em 0 #E9F8F5, -0.05em 0.05em 0 #E9F8F5, 0.05em -0.05em 0 #E9F8F5, 0.05em 0 0 #E9F8F5, -0.05em 0 0 #E9F8F5, 0 0.05em 0 #E9F8F5, 0 -0.05em 0 #E9F8F5;
  background-image: linear-gradient(0deg, rgba(18, 164, 181, 0.6) 0.2em, transparent 0.2em); }

.main-wrap > .content > .section {
  margin: calc( 25px + 1vh ) 0 calc( 25px + 5vh ); }
.main-wrap > .content > .section > .list-article > .item {
  border-bottom-color: #12A4B5; }
.main-wrap > .content > .section > .list-article > .item > a:hover {
  background-color: rgba(18, 164, 181, 0.24); }

.more-wrap {
  padding-right: calc( 50px + .5em ); }
.more-wrap > .more {
  float: right;
  margin-right: calc( -1 * ( 50px + .5em ) );
  padding: 5px 8px;
  font-size: 0.75rem;
  line-height: 1;
  text-shadow: none; }
.more-wrap > .more::before {
  content: "more"; }
.more-wrap > .more:hover {
  background-color: rgba(18, 164, 181, 0.48); }

.main-wrap > .content > .col2 {
  margin: 2vh 0; }
.main-wrap > .content > .col2 > .left > .section > .title {
  margin-bottom: 8px;
  border-radius: 0.3em;
  padding: 16px 0.5em 14px;
  font-size: 1.25em;
  background-color: rgba(18, 164, 181, 0.36); }
.main-wrap > .content > .col2.products > .left {
  margin-right: calc( 10px + 1vw );
  width: 25%; }
.main-wrap > .content > .col2.products > .right {
  flex-grow: 1;
  max-width: 75%; }

.main-wrap > .content .table {
  width: 100%;
  max-width: 100%; }
.main-wrap > .content .table .tr {
  border-bottom: 1px solid #12A4B5; }
.main-wrap > .content .table .tr:not(.thead):not(.tfoot):hover {
  background-color: rgba(18, 164, 181, 0.12); }
.main-wrap > .content .table .tr .td, .main-wrap > .content .table .tr .th {
  padding: 0.2em 3px; }
.main-wrap > .content .table .tr .td > span, .main-wrap > .content .table .tr .td > .table-btn, .main-wrap > .content .table .tr .th > span, .main-wrap > .content .table .tr .th > .table-btn {
  padding: 0.8em 5px; }
.main-wrap > .content .table .tr .td > span, .main-wrap > .content .table .tr .th > span {
  display: inline-block; }
.main-wrap > .content .table .tr .td > .table-btn, .main-wrap > .content .table .tr .th > .table-btn {
  display: block; }
.main-wrap > .content .table .tr .td > .table-btn:hover, .main-wrap > .content .table .tr .th > .table-btn:hover {
  background-color: #2bcbcd; }
.main-wrap > .content .table:last-child {
  border-bottom-width: 0; }
.main-wrap > .content .table .thead {
  border-bottom-width: 0;
  color: #FFF;
  background-color: #12A4B5; }
.main-wrap > .content .table .thead > .td, .main-wrap > .content .table .thead > .th {
  padding: 0.5em 1em; }

.main-wrap .downloads .time {
  flex-shrink: 0;
  width: 35%; }
.main-wrap .downloads .btn {
  flex-shrink: 0;
  width: 60px; }
.main-wrap .downloads .btn > a {
  height: 100%; }

.price {
  display: block;
  border-bottom-width: 0;
  width: 100%;
  text-align: right; }
.price > .item {
  display: inline-block;
  margin-top: 0.5em;
  margin-right: 0.5em;
  padding: 0.2em 3px;
  color: #FFF;
  line-height: 1;
  background-color: #12A4B5; }
.price > .item:last-child {
  margin-right: 0; }
.price > .item > span {
  padding: 0.2em; }

.main-wrap > .content .table-flex > .tr {
  align-items: stretch; }
.main-wrap > .content .table-flex > .tr > .td {
  display: flex;
  align-items: center;
  justify-content: center;
  vertical-align: middle; }
.main-wrap > .content .table-flex > .tr > .title {
  width: 80%;
  min-width: 0%;
  max-width: 80%; }
.main-wrap > .content .table-flex > .tr > .time {
  align-self: center;
  width: 10%; }
.main-wrap > .content .table-flex > .tr > .btn {
  width: 10%; }
.main-wrap > .content .table-flex > .tr > .btn > .table-btn {
  display: flex;
  align-items: center;
  align-content: stretch;
  justify-content: center;
  width: 100%;
  height: 100%; }

.main-wrap > .content .checkout > .tr:not(.thead):not(.tfoot):hover {
  background-color: transparent !important; }
.main-wrap > .content .checkout > .tr > .btn {
  width: 70px; }
.main-wrap > .content .checkout > .tr > .btn > .table-btn {
  width: 100%; }
.main-wrap > .content .checkout > .tr > .inp .btn {
  font-size: 0.625em; }
.main-wrap > .content .checkout > .tr > .inp .inp {
  padding: 0.25em; }

.main-wrap > .content .form {
  width: 100%; }
.main-wrap > .content .form .lb:not(.lb-include) {
  display: block;
  margin-bottom: calc( 10px + .8vh );
  padding-top: 2em;
  width: 100%; }
.main-wrap > .content .form .lb {
  position: relative; }
.main-wrap > .content .form .lb > .inp, .main-wrap > .content .form .lb > .addr-wrap .inp {
  padding: 6px 8px 3px;
  width: 100%;
  line-height: 1.6;
  font-size: 1em;
  background-color: rgba(18, 164, 181, 0.24);
  transition: background-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, color 0.15s ease-in-out; }
.main-wrap > .content .form .lb > .inp:focus, .main-wrap > .content .form .lb > .addr-wrap .inp:focus {
  background-color: rgba(255, 255, 255, 0.56);
  box-shadow: 0.1em 0.1em 0.1em rgba(0, 0, 0, 0.12) inset; }
.main-wrap > .content .form .lb > .inp:read-only, .main-wrap > .content .form .lb > .inp:read-only:focus, .main-wrap > .content .form .lb > .addr-wrap .inp:read-only, .main-wrap > .content .form .lb > .addr-wrap .inp:read-only:focus {
  background-color: rgba(18, 164, 181, 0.24);
  box-shadow: none;
  cursor: not-allowed; }
.main-wrap > .content .form .lb > .addr-wrap .inp {
  width: auto;
  height: auto; }
.main-wrap > .content .form .lb > .addr-wrap > .city {
  margin-bottom: 0.5em; }
.main-wrap > .content .form .lb > .addr-wrap > .city > .inp {
  margin-right: 0.5em;
  width: 100%; }
.main-wrap > .content .form .lb > .addr-wrap > .city > .inp:last-child {
  margin-right: 0; }
.main-wrap > .content .form .lb > .addr-wrap > .city > .zip {
  max-width: 40%; }
.main-wrap > .content .form .lb > .inp-area {
  width: 100%;
  min-width: 100%;
  max-width: 100%;
  min-height: calc( 5.2em + 9.4vh ); }
.main-wrap > .content .form .lb > .inp-checkbox {
  display: none; }
.main-wrap > .content .form .lb > .inp-checkbox ~ .lb-txt {
  user-select: none; }
.main-wrap > .content .form .lb > .inp-checkbox ~ .lb-txt::before {
  content: "";
  display: inline-block;
  margin-right: 0.5em;
  width: 1em;
  font-family: "FontAwesome";
  color: inherit;
  font-size: inherit;
  line-height: inherit; }
.main-wrap > .content .form .lb > .inp-checkbox:checked ~ .lb-txt::before {
  content: ""; }
.main-wrap > .content .form .lb:not(.lb-include) > .lb-txt {
  top: 0;
  left: 0;
  position: absolute;
  font-size: 1.25em; }
.main-wrap > .content .form .lb > .lb-txt {
  max-width: 100%;
  line-height: 1.6; }
.main-wrap > .content .form .lb > .lb-txt > .err {
  display: none;
  font-size: 0.7em;
  color: #f94b4b; }
.main-wrap > .content .form .lb > .lb-txt > .err::before {
  content: "(";
  margin-right: 0.2em; }
.main-wrap > .content .form .lb > .lb-txt > .err::after {
  content: ")";
  margin-left: 0.2em; }
.main-wrap > .content .form .lb > .inp:invalid, .main-wrap > .content .form .lb > .inp.error {
  color: #FFF;
  background-color: #f94b4b; }
.main-wrap > .content .form .lb > .inp:invalid ~ .lb-txt > .err, .main-wrap > .content .form .lb > .inp.error ~ .lb-txt > .err {
  display: inline-block; }
.main-wrap > .content .form .lb > .addr-wrap.error ~ .lb-txt > .err {
  display: inline-block; }
.main-wrap > .content .form .lb > .lb-include {
  display: inline-block;
  padding: 0.5em 0.475em;
  padding-left: 0.5em;
  font-size: 0.875em; }
.main-wrap > .content .form .lb-area {
  height: auto; }
.main-wrap > .content .form .form-act {
  margin-top: calc( 20px + 2vh ); }
.main-wrap > .content .form .form-act:not(.checkout) {
  align-content: stretch;
  align-items: stretch;
  justify-content: flex-end;
  margin-top: calc( 20px + 3.5vh );
  padding: 0;
  height: 78px; }
.main-wrap > .content .form .form-act > .g-recaptcha {
  margin-right: calc( 1em + 5% ); }
.main-wrap > .content .form .form-act > .btn {
  border: 1px solid transparent;
  padding: 0.825em 2em 0.8em; }
.main-wrap > .content .form .form-act > .btn-cancel {
  border-color: rgba(0, 0, 0, 0.08); }
.main-wrap > .content .form .checkout > .btn-cancel {
  float: left; }
.main-wrap > .content .form .checkout > .btn-submit {
  float: right; }

.form-txt .inp {
  word-break: break-all; }

.carousel {
  position: relative;
  padding-bottom: 3em;
  width: 100%;
  overflow: hidden; }
.carousel > .carousel-indicators {
  bottom: 0;
  position: absolute;
  padding: 0.4em;
  width: 100%;
  text-align: center;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  cursor: default;
  transition: opacity 0.15s ease-in-out;
  z-index: 50; }
.carousel > .carousel-indicators > li {
  display: inline-block;
  margin-right: 0.1em;
  margin-left: 0.1em;
  border: 0.15em solid #4EBFE5;
  border-radius: 0.4em;
  width: 0.8em;
  height: 0.8em;
  background-color: transparent;
  transition: background-color 0.15s ease-in-out;
  cursor: pointer; }
.carousel > .carousel-indicators > li:hover {
  background-color: #7CBDB1; }
.carousel > .carousel-indicators > li.active {
  background-color: #4EBFE5;
  cursor: default; }
.carousel > .carousel-inner {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  overflow: hidden; }
.carousel > .carousel-inner > .item {
  display: none;
  position: relative;
  height: 100%;
  transition: left 0.6s ease-in-out; }
.carousel > .carousel-inner > .item img {
  max-width: 100%;
  max-height: 100%; }
.carousel > .carousel-inner > .active, .carousel > .carousel-inner > .next, .carousel > .carousel-inner > .prev {
  display: block; }
.carousel > .carousel-inner > .active {
  left: 0; }
.carousel > .carousel-inner > .next, .carousel > .carousel-inner > .prev {
  top: 0;
  position: absolute;
  width: 100%; }
.carousel > .carousel-inner > .next, .carousel > .carousel-inner > .active.right {
  left: 100%; }
.carousel > .carousel-inner > .prev, .carousel > .carousel-inner > .active.left {
  left: -100%; }
.carousel > .carousel-inner > .next.left, .carousel > .carousel-inner > .prev.right {
  left: 0; }
.carousel > .carousel-control {
  top: 0;
  bottom: 0;
  position: absolute;
  width: 15%;
  font-size: 20px;
  text-align: center;
  opacity: 0.5;
  transition: all 0.15s ease-in-out; }
.carousel > .carousel-control > .sr-only {
  display: none; }
.carousel > .carousel-control.left {
  left: 0; }
.carousel > .carousel-control.right {
  right: 0; }

.main-wrap > .content .mini-product-wrap, .main-wrap > .content .product-wrap {
  flex-wrap: wrap;
  align-items: stretch;
  justify-content: center;
  padding: 10px 2%;
  padding-top: 0; }
.main-wrap > .content .mini-product-wrap > .mini-product, .main-wrap > .content .mini-product-wrap > .product, .main-wrap > .content .product-wrap > .mini-product, .main-wrap > .content .product-wrap > .product {
  display: inline-block;
  margin-top: calc( 10px + 2vh );
  margin-right: calc( 5px + 2% );
  width: calc( 25% - 5px - 2% );
  transition: all 0.15s ease-in-out; }
.main-wrap > .content .mini-product-wrap > .mini-product:nth-child(4n), .main-wrap > .content .mini-product-wrap > .product:nth-child(4n), .main-wrap > .content .product-wrap > .mini-product:nth-child(4n), .main-wrap > .content .product-wrap > .product:nth-child(4n) {
  margin-right: 0; }
.main-wrap > .content .mini-product-wrap > .mini-product:hover, .main-wrap > .content .mini-product-wrap > .product:hover, .main-wrap > .content .product-wrap > .mini-product:hover, .main-wrap > .content .product-wrap > .product:hover {
  background-color: rgba(18, 164, 181, 0.24);
  box-shadow: 0 0 0.4em 0 rgba(18, 164, 181, 0.24); }
.main-wrap > .content .mini-product-wrap > .mini-product > a > .img-wrap, .main-wrap > .content .mini-product-wrap > .product > a > .img-wrap, .main-wrap > .content .product-wrap > .mini-product > a > .img-wrap, .main-wrap > .content .product-wrap > .product > a > .img-wrap {
  border-radius: 0.5em; }
.main-wrap > .content .mini-product-wrap > .mini-product > a, .main-wrap > .content .mini-product-wrap > .product > a, .main-wrap > .content .product-wrap > .mini-product > a, .main-wrap > .content .product-wrap > .product > a {
  padding: 8px 8px 12px;
  width: 100%;
  height: 100%; }
.main-wrap > .content .mini-product-wrap > .mini-product > a > .img-wrap, .main-wrap > .content .mini-product-wrap > .product > a > .img-wrap, .main-wrap > .content .product-wrap > .mini-product > a > .img-wrap, .main-wrap > .content .product-wrap > .product > a > .img-wrap {
  overflow: hidden; }
.main-wrap > .content .mini-product-wrap > .mini-product > a > .title, .main-wrap > .content .mini-product-wrap > .mini-product > a > .price, .main-wrap > .content .mini-product-wrap > .product > a > .title, .main-wrap > .content .mini-product-wrap > .product > a > .price, .main-wrap > .content .product-wrap > .mini-product > a > .title, .main-wrap > .content .product-wrap > .mini-product > a > .price, .main-wrap > .content .product-wrap > .product > a > .title, .main-wrap > .content .product-wrap > .product > a > .price {
  padding: 5px 8px; }
.main-wrap > .content .mini-product-wrap > .mini-product > a > .title, .main-wrap > .content .mini-product-wrap > .product > a > .title, .main-wrap > .content .product-wrap > .mini-product > a > .title, .main-wrap > .content .product-wrap > .product > a > .title {
  margin: 8px auto;
  max-height: 3.4em;
  word-break: break-word;
  overflow: hidden; }
.main-wrap > .content .mini-product-wrap > .mini-product > a > .price, .main-wrap > .content .mini-product-wrap > .product > a > .price, .main-wrap > .content .product-wrap > .mini-product > a > .price, .main-wrap > .content .product-wrap > .product > a > .price {
  padding-top: 0;
  padding-bottom: 0;
  font-size: 0.875em;
  color: #12A4B5; }
.main-wrap > .content .mini-product-wrap > .mini-product > a > .price::before, .main-wrap > .content .mini-product-wrap > .product > a > .price::before, .main-wrap > .content .product-wrap > .mini-product > a > .price::before, .main-wrap > .content .product-wrap > .product > a > .price::before {
  content: "$";
  margin-right: 0.5em; }
.main-wrap > .content .mini-product-wrap > .mini-product > a > .price.discount > .org, .main-wrap > .content .mini-product-wrap > .product > a > .price.discount > .org, .main-wrap > .content .product-wrap > .mini-product > a > .price.discount > .org, .main-wrap > .content .product-wrap > .product > a > .price.discount > .org {
  color: #86e8f3;
  text-decoration: line-through;
  transition: all 0.15s ease-in-out; }
.main-wrap > .content .mini-product-wrap > .mini-product > a > .price.discount > .new, .main-wrap > .content .mini-product-wrap > .product > a > .price.discount > .new, .main-wrap > .content .product-wrap > .mini-product > a > .price.discount > .new, .main-wrap > .content .product-wrap > .product > a > .price.discount > .new {
  margin-left: 0.5em;
  color: #12A4B5; }

.main-wrap > .content .mini-product-wrap > .mini-product > a {
  display: block; }

.main-wrap > .content .product-wrap > .product {
  margin-top: calc( 10px + .8vh ); }
.main-wrap > .content .product-wrap > .product:hover > a > .price.discount > .org {
  color: #80cad5; }
.main-wrap > .content .product-wrap > .product:hover > a > .btns > .btn-product-act {
  background-color: #12A4B5; }
.main-wrap > .content .product-wrap > .product > a > .btns {
  margin-top: 1em;
  text-align: center;
  white-space: nowrap; }
.main-wrap > .content .product-wrap > .product > a > .btns > .btn-product-act {
  padding: 0.5em 0.8em;
  width: 100%;
  color: #FFF;
  background-color: #93D2DB; }
.main-wrap > .content .product-wrap > .product > a > .btns > .btn-product-act::before {
  margin-right: 0.5em; }
.main-wrap > .content .product-wrap > .product > a > .btns > .btn-product-act:hover {
  background-color: #14b9cc; }

.list-side {
  border-radius: 0.3em;
  padding: 8px;
  background-color: rgba(18, 164, 181, 0.24); }
.list-side > .item {
  margin-bottom: 8px;
  border-radius: 0.3em; }
.list-side > .item:last-child {
  margin-bottom: 0; }
.list-side > .item > .cate, .list-side > .item > .list-slide {
  padding: 7px 0.5em 5px; }
.list-side > .item > .cate {
  display: block;
  border-radius: 0.3em;
  width: 100%;
  font-weight: bold;
  text-align: left;
  word-break: break-word;
  transition: all 0.15s ease-in-out; }
.list-side > .item:not(.open) > .cate:hover {
  background-color: rgba(233, 248, 245, 0.64); }
.list-side > .item > .cate, .list-side > .item > .list-slide > .item > .link {
  cursor: pointer; }
.list-side > .item > .list-slide {
  display: none;
  padding-bottom: 7px; }
.list-side > .item > .list-slide > .item {
  margin-bottom: 3px; }
.list-side > .item > .list-slide > .item:last-child {
  margin-bottom: 0; }
.list-side > .item > .list-slide > .item > a {
  display: block;
  border-radius: 0.3em;
  padding: 7px 0.5em 5px;
  font-size: 1rem;
  word-break: break-word; }
.list-side > .item > .list-slide > .item:not(.now) > a:hover {
  background-color: #9bedae; }
.list-side > .item > .list-slide > .now > a {
  background-color: rgba(18, 164, 181, 0.24); }
.list-side > .open {
  background-color: #E9F8F5; }

.product-info {
  width: 100%; }
.product-info > .images {
  margin-right: 3%;
  width: 32%; }
.product-info > .images > .img-wrap {
  max-height: 500px; }
.product-info > .images > .img-list {
  flex-wrap: wrap;
  padding: 15px 0 0;
  overflow: hidden; }
.product-info > .images > .img-list > .item {
  display: inline-block;
  margin-right: 2%;
  margin-bottom: 8px;
  width: calc( ( 100% - 2% * 2 ) / 3 ); }
.product-info > .images > .img-list > .item:nth-child(3n) {
  margin-right: 0; }
.product-info > .images > .img-list > .item:last-child {
  margin-right: 0; }
.product-info > .images > .img-list > .item > a {
  display: block; }
.product-info > .details {
  min-width: 0; }
.product-info > .details > .amount > .lb {
  width: 100%; }
.product-info > .details > .amount > .lb .lb-txt {
  white-space: nowrap; }
.product-info > .details > .amount > .lb .inp-wrap {
  width: 100%; }
.product-info > .details > .amount > .lb .inp {
  width: 100%; }
.product-info > .details > .amount > .btn-submit {
  display: block;
  margin-top: 2em;
  width: 100%;
  color: #FFF;
  background-color: #93D2DB; }
.product-info > .details > .amount > .btn-submit::before {
  margin-right: 0.5em; }
.product-info > .details > .amount > .btn-submit:hover {
  background-color: #14b9cc; }

.product-info > .details > h3.title {
    margin-bottom: 1em;
    padding: 0.5em 0.5em 0.475em;
    word-break: break-all;
    font-size: 1.5em;
    background-color: rgba(18, 164, 181, 0.24);
}

.products > .right > .section > h3.title {
  margin-bottom: 1em;
  padding: 0.5em 0.5em 0.475em;
  word-break: break-all;
  font-size: 1.5em;
  background-color: rgba(18, 164, 181, 0.24); }

.menu > .samll-menu {
  position: relative;
  display: none; }
.menu > .samll-menu > .btn {
  display: block;
  padding: 0.75em 1.5em 0.725em;
  width: 100%;
  color: #FFF;
  font-weight: bold;
  background-color: #12A4B5; }
.menu > .samll-menu > .btn:hover {
  background-color: #14b9cc; }
.menu > .samll-menu > .dropdown-menu {
  display: none;
  padding: 0 calc( 8px + .8vw ) calc( 5px + 1vh ); }
.menu > .samll-menu.open > .btn {
  background-color: #0f8b99; }
.menu > .samll-menu.open > .dropdown-menu {
  display: block; }

.foot {
  padding-top: 50px;
  padding-right: calc( 5px + 1vw );
  padding-bottom: calc( 10px + .5vh );
  padding-left: calc( 5px + 1vw );
  color: rgba(0, 0, 0, 0.65);
  background-color: #93D2DB; }
.foot > .indent > .flex {
  margin-bottom: calc( 10px + .5vh ); }
.foot > .indent > .flex:last-child {
  margin-bottom: 0; }
.foot .box > .title {
  margin-bottom: 1em;
  font-size: 1.25em; }
.foot .box .list > .item {
  margin: 0.425em 0; }
.foot .box .fa-wrap {
  padding-left: 1em; }
.foot .box .fa-wrap::before {
  float: left;
  margin-right: 0.5em;
  margin-left: -1em;
  width: 1em;
  text-align: center; }
.foot .box .fa-wrap > a {
  color: inherit;
  background-image: linear-gradient(0deg, transparent 0.3em, transparent 0.3em);
  transition: all 0.15s ease-in-out; }
.foot .box .fa-wrap > a:hover {
  color: rgba(0, 0, 0, 0.8);
  background-image: linear-gradient(0deg, rgba(255, 255, 255, 0.36) 0.3em, transparent 0.3em); }
.foot .lic {
  margin-top: calc( 20px + 1vh );
  font-size: 0.75em; }
.foot .lic > .lic-txt, .foot .lic .select, .foot .lic a {
  padding: 8px 12px; }
.foot .lic > .lic-txt {
  padding-right: 0;
  padding-left: 0; }
.foot .lic .select, .foot .lic a {
  margin-left: 1em;
  transition: background-color 0.15s ease-in-out; }
.foot .lic .select:hover, .foot .lic a:hover {
  background-color: rgba(255, 255, 255, 0.36); }
.foot .lic a {
  display: inline-block; }

@media screen and (max-width: 1069px) {
  .col2.index {
    display: block; }
  .col2.index .left, .col2.index .right {
    margin: 0 0 calc( 25px + 5vh );
    width: 100%; }

  .product-info > .images > .img-list > .item {
    width: calc( ( 100% - 2% * 1 ) / 2 ); }
  .product-info > .images > .img-list > .item:nth-child(3n) {
    margin-right: 2%; }
  .product-info > .images > .img-list > .item:nth-child(2n) {
    margin-right: 0; } }
@media screen and (max-width: 767px) {
  .products {
    display: block; }
  .products > .left, .products > .right {
    display: block;
    margin-right: 0;
    margin-left: 0;
    width: 100%;
    max-width: 100% !important; }
  .products > .left {
    margin-bottom: calc( 10px + 1vh );
    padding: 0 calc( 5px + 1vw );
    width: 100% !important; }
  .products > .left > .section {
    display: none; }
  .products > .left > .samll-menu {
    display: block !important; }

  .head .nav-list {
    display: none; }

  .head .small-nav-list {
    display: inline-block !important; }

  .main-wrap > .content .mini-product-wrap > .mini-product, .main-wrap > .content .product-wrap > .product {
    width: calc( 33% - 5px - 2% ); }

  .main-wrap > .content .mini-product-wrap > .mini-product:nth-child(4n), .main-wrap > .content .product-wrap > .product:nth-child(4n) {
    margin-right: calc( 5px + 2% ); }

  .main-wrap > .content .mini-product-wrap > .mini-product:nth-child(3n), .main-wrap > .content .product-wrap > .product:nth-child(3n) {
    margin-right: 0; }

  .list-fixed {
    display: block; }
  .list-fixed > .item {
    display: block; }
  .list-fixed > .item > .left, .list-fixed > .item > .right {
    display: block;
    width: 100%; }
  .list-fixed > .item > .left {
    text-align: left; }
  .list-fixed > .item > .right {
    text-align: center; } }
@media screen and (max-width: 649px) {
  .checkout .tr > .td.inp .btn {
    display: none; }

  .product-info {
    display: block; }
  .product-info > .images {
    display: block;
    margin-right: 0;
    width: 100%; }
  .product-info > .images > .img-list > .item {
    width: calc( ( 100% - 2% * 7 ) / 8 ); }
  .product-info > .images > .img-list > .item:nth-child(2n) {
    margin-right: 2%; }
  .product-info > .images > .img-list > .item:nth-child(8n) {
    margin-right: 0; }

  .foot .f-dym {
    display: block; }
  .foot .f-dym > .box {
    display: block;
    margin-bottom: calc( 10px + 2vh );
    width: 100%; }
  .foot .f-dym > .box .list {
    padding: 0 5%; }

  .lic {
    margin-top: 4vh;
    text-align: center; }
  .lic > .box:last-child {
    margin-bottom: 0; } }
@media screen and (max-width: 480px) {
  .checkout .tr > .btn {
    width: 100% !important; }
  .checkout .tr > .btn > .table-btn {
    margin-top: 10px; }
  .checkout .tr > .inp .inp-wrap {
    width: 100%; }
  .checkout .tr > .inp .inp-wrap .btn {
    display: inline-block !important; }

  .main-wrap > .content .mini-product-wrap > .mini-product, .main-wrap > .content .product-wrap > .product {
    width: calc( 50% - 5px - 2% ); }

  .main-wrap > .content .mini-product-wrap > .mini-product:nth-child(3n), .main-wrap > .content .product-wrap > .product:nth-child(3n) {
    margin-right: calc( 5px + 2% ); }

  .main-wrap > .content .mini-product-wrap > .mini-product:nth-child(2n), .main-wrap > .content .product-wrap > .product:nth-child(2n) {
    margin-right: 0; }

  .product-info > .images > .img-list > .item {
    width: calc( ( 100% - 2% * 5 ) / 6 ); }
  .product-info > .images > .img-list > .item:nth-child(8n) {
    margin-right: 2%; }
  .product-info > .images > .img-list > .item:nth-child(6n) {
    margin-right: 0; }

  .checkout-step > .crumb {
    padding: 0.3rem 0.5em 0.275em; }

  .checkout {
    display: block; }
  .checkout .tr {
    display: block; }
  .checkout .thead {
    display: none; }
  .checkout .td {
    display: block !important;
    width: 100%; }
  .checkout .td::before {
    content: attr(aria-label) "";
    display: block;
    font-weight: bold;
    text-align: left; }
  .checkout .table-btn {
    margin: 0 auto; }

  .price {
    padding: 0 2%; }
  .price > .item {
    display: block;
    margin-right: 0; } }
@media screen and (max-width: 374px) {
  .main-wrap > .content .mini-product-wrap > .mini-product, .main-wrap > .content .product-wrap > .product {
    margin-right: 0;
    width: 100%; }

  .product-info > .images > .img-list > .item {
    width: calc( ( 100% - 30px ) / 5 ); }
  .product-info > .images > .img-list > .item:nth-child(6n) {
    margin-right: 10px; }
  .product-info > .images > .img-list > .item:nth-child(5n) {
    margin-right: 0; } }
